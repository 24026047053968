import * as React from "react";
import Layout from "../components/layout";
// import * as sections from "../components/sections"
// import Fallback from "../components/fallback"
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../LinkTranslated";
import SimpleLocalize from "../SimpleLocalize";
import Preloader from "../components/preloader";
import { PageBanner } from "../components/sections/PageBanner";
import { PageNavBar } from "../components/sections/pageNavBar";
import { ValuePropsHome } from "../components/sections/ValuePropsHome";
import { ServiceFeatures } from "../components/sections/ServiceFeatures";
import { HowWeWork } from "../components/sections/HowWeWork";
import { PriceTable } from "../components/sections/PriceTable";
import { Cta } from "../components/sections/Cta";
import { ValueProposition } from "../components/sections/ValueProposition";

export default function Responsibility(props) {
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "/js/slick-animation.min.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  return (
    <SimpleLocalize {...props}>
      <Layout>
        <LinkTranslated href="/">
          {/* <FormattedMessage id="header--service-1" /> */}
          {/* <PageBanner textId="header--service-1" imagePath="/img/page-banner.jpg"/> */}
        </LinkTranslated>
        <PageBanner textId="header--about-2" imagePath="/img/slide1.jpg" />
        <PageNavBar
          fromId="header--home"
          currentId="header--about-2"
        ></PageNavBar>

        <section class="about-us-wrapper section-padding">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-5 text-center text-lg-start"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  class="about-img"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img src="/img/logo.png" alt="" />
                </div>
              </div>
              <div class="col-lg-7 mt-5 mt-lg-0 ps-lg-5">
                <div class="block-contents">
                  <div class="section-title">
                    <h5 class="textleft relative">Mega</h5>
                    <span>
                      <FormattedMessage id="header--about-2" />
                    </span>
                    {/* <h2><FormattedMessage id="value--title-1" /><span><FormattedMessage id="value--title-2" /></span></h2> */}
                  </div>
                </div>
                <div class="about-info-contents">
                  <p>
                    <FormattedMessage id="about2--story-1" />
                  </p>
                  <p>
                    <FormattedMessage id="about2--story-2" />
                  </p>
                </div>

              </div>
            </div>
            {/* <div
              class="mt-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src="/img/trucks.jpg" alt="" style={{ width: "70%" }} />
            </div> */}
          </div>
        </section>

        <div style={{ height: "80px" }} />
        <ServiceFeatures />
        <div style={{ height: "120px" }} />
        {/* <HowWeWork /> */}
        {/* <PriceTable /> */}
        <ValueProposition />
      </Layout>
    </SimpleLocalize>
  );
}
